import { html } from "lit";
import { property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import FormElement, { FormElementProps } from "./form-element";

import "./atlas-helper-text/atlas-helper-text";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-text/atlas-text";

export type FormControlProps = FormElementProps &
    WithPopoverProps & {
        "label": string;
        "helper-text": string;
        "hide-optional": boolean;
    };

export default class FormControl extends WithPopoverMixin(FormElement) {
    /** A label que ficará acima do input determinando o nome do mesmo*/
    @property({ type: String }) label = "";

    /** O texto auxiliar que pode ser utilizado no input */
    @property({ type: String, attribute: "helper-text" }) helperText = "";

    /** Indica se o texto (Opcional) deve ser oculto da label */
    @property({ type: Boolean, attribute: "hide-optional" }) hideOptional: boolean;

    protected _hasSlottedHelperText = false;

    protected renderHelperText(isToggleHelper?: boolean) {
        return when(
            this.helperText,
            () => html`
                <atlas-helper-text id=${this.id} ?is-toggle-helper=${isToggleHelper}>
                    ${this.helperText}
                </atlas-helper-text>
            `,
            () => html`
                <slot
                    name="helper-text"
                    @slotchange=${() => this.onHelperTextSlotChange(isToggleHelper || false)}
                ></slot>
            `
        );
    }

    protected renderLabel() {
        return when(
            this.label !== "",
            () => html`
                <label for="${this.id}" class="form-label">
                    <atlas-text>${this.label} ${this.renderOptional()}</atlas-text>
                    ${this.renderPopoverInfoIcon()}
                </label>
            `
        );
    }

    private onHelperTextSlotChange(isToggleHelper: boolean) {
        const helperTextSlot = this.shadowRoot.querySelector("slot[name=helper-text]") as HTMLSlotElement;
        const assignedElements = helperTextSlot?.assignedElements() || [];
        const slottedHelperTexts = assignedElements.filter((element) => element.tagName === "ATLAS-HELPER-TEXT");

        slottedHelperTexts.forEach((element) => {
            element.setAttribute("id", this.id);
            element.toggleAttribute("is-toggle-helper", isToggleHelper);
        });

        this._hasSlottedHelperText = slottedHelperTexts.length > 0;
    }

    private renderOptional() {
        return when(
            !this.required && !this.hideOptional,
            () => html`<span class="form-label-optional">(Opcional)</span>`
        );
    }
}
