import FroalaEditor, { type FroalaOptions } from "froala-editor";

FroalaEditor.DefineIcon("variables", {
    NAME: "Variáveis",
    template: "text"
});

FroalaEditor.RegisterCommand("variables", {
    title: "Variáveis",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
        this.events.trigger("variables:click", []);
    }
});

FroalaEditor.PLUGINS.fixPasteBug = function fixPasteBugPlugin(editor: any) {
    return {
        _init() {
            editor.events.on("paste.after", () => {
                editor.selection.save();

                const t = document.createElement("template");
                t.innerHTML = editor.html.get(true);

                const offender = t.content.querySelector("#isPasted");

                if (offender) {
                    offender.removeAttribute("id");
                    editor.html.set(t.innerHTML);
                }

                editor.selection.restore();
            });
        }
    };
};

export { FroalaEditor, type FroalaOptions };
