import {css} from "lit-element/lit-element.js";
export default css`:host([width=full]) {
  width: 100%;
}

:host([width=xs]) {
  width: 96px;
}

:host([width=sm]) {
  width: 152px;
}

:host([width=md]) {
  width: 264px;
}

:host([width=lg]) {
  width: 380px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjMzUxMTQzNjI3NS9zcmMvcGFja2FnZXMvd2ViY29tcG9uZW50cy9zcmMvY29tcG9uZW50cy9mb3JtL2F0bGFzLXNlbGVjdCIsInNvdXJjZXMiOlsiLi4vLi4vLi4vc3R5bGVzL2NvbXBvbmVudHMvX2lucHV0LXdpZHRoLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBU0k7RUFDSSxPQVZPOzs7QUFTWDtFQUNJLE9BVk87OztBQVNYO0VBQ0ksT0FWTzs7O0FBU1g7RUFDSSxPQVZPOzs7QUFTWDtFQUNJLE9BVk8iLCJzb3VyY2VzQ29udGVudCI6WyIkaW5wdXQtd2lkdGhzOiAoXG4gICAgZnVsbDogMTAwJSxcbiAgICB4czogOTZweCxcbiAgICBzbTogMTUycHgsXG4gICAgbWQ6IDI2NHB4LFxuICAgIGxnOiAzODBweFxuKTtcblxuQGVhY2ggJHdpZHRoLCAkdmFsdWUgaW4gJGlucHV0LXdpZHRocyB7XG4gICAgOmhvc3QoW3dpZHRoPVwiI3skd2lkdGh9XCJdKSB7XG4gICAgICAgIHdpZHRoOiAkdmFsdWU7XG4gICAgfVxufVxuIl19 */`;
