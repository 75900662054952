import { html } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

import { getAllFormElements, getFormValues, INPUT_ELEMENTS } from "@/helpers/form";

import { emit } from "@/internals/events";

import DeviceController from "@/controllers/device-controller";

import type AtlasFilterGroup from "@/components/form/atlas-filter-group/atlas-filter-group";
import type AtlasInput from "@/components/form/atlas-input/atlas-input";
import type AtlasButton from "@/components/display/atlas-button/atlas-button";
import type FormElement from "../form-element";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-filter-form.scss";
import "@/components/display/atlas-button/atlas-button";

export type FilterFormProps = AtlasElementProps & {
    type: "simple" | "advanced";
};

/**
 * @dependency atlas-button
 * @slot - Slot padrão para adicionar grupos de filtros(coluna automática, nem sempre é adequado usar)
 * @slot col-1 - Slot para adicionar um grupo de filtros na primeira coluna
 * @slot col-2 - Slot para adicionar um grupo de filtros na segunda coluna
 * @slot col-3 - Slot para adicionar um grupo de filtros na terceira coluna
 * @slot col-4 - Slot para adicionar um grupo de filtros na quarta coluna
 * 
 * @event {CustomEvent} atlas-filter-form-count - Evento disparado quando o contador de filtros é alterado
 * @event {CustomEvent} atlas-open-advanced-filter - Evento disparado quando o botão de filtros avançados é clicado
 * @event {CustomEvent} atlas-clean-filter - Evento disparado quando o botão de limpar é clicado
 * @event {CustomEvent} atlas-apply-filter - Evento disparado quando o botão de aplicar é clicado
 * @event {CustomEvent} atlas-filter-close - Evento disparado quando o formulário de filtro é fechado
 * 
 * 
 * @tag atlas-filter-form
 */
@customElement("atlas-filter-form")
export default class AtlasFilterForm extends AtlasElement {
    static styles = styles;

    /** Define o tipo do formulário, pode ser "simple" ou "advanced(o filter determina essa propriedade através do slot o qual colocamos o form)" */
    @property({ type: String }) type: "simple" | "advanced";

    @state() public hasAdvancedFilter = false;

    @state() public advancedFilterCounter = 0;

    @queryAssignedElements({ selector: "atlas-filter-group", flatten: true })
    private _filterGroups!: Array<AtlasFilterGroup>;

    @queryAssignedElements({ slot: "col-1", selector: "atlas-filter-group", flatten: true })
    private _filterGroupsCol1!: Array<AtlasFilterGroup>;

    @queryAssignedElements({ slot: "col-2", selector: "atlas-filter-group", flatten: true })
    private _filterGroupsCol2!: Array<AtlasFilterGroup>;

    @queryAssignedElements({ slot: "col-3", selector: "atlas-filter-group", flatten: true })
    private _filterGroupsCol3!: Array<AtlasFilterGroup>;

    @queryAssignedElements({ slot: "col-4", selector: "atlas-filter-group", flatten: true })
    private _filterGroupsCol4!: Array<AtlasFilterGroup>;

    private _filterHiddenInputs: Array<AtlasInput> = [];

    private _deviceController = new DeviceController(this);

    /** @internal */
    public async getUpdateComplete() {
        const filterGroups = this.getFilterGroups();
        await super.getUpdateComplete();
        await Promise.all(filterGroups.map((element) => element.updateComplete));
        return true;
    }

    /** 
     * Desabilita os botões de ação do formulário
     */
    public disableButtons() {
        const atlasButtons = this.shadowRoot.querySelectorAll("atlas-button");

        atlasButtons.forEach((button) => {
            button.disable();
        });
    }

    /** 
     * Habilita os botões de ação do formulário
     */
    public enableButtons() {
        const atlasButtons = this.shadowRoot.querySelectorAll("atlas-button");

        atlasButtons.forEach((button) => {
            button.loading = false;
            button.enable();
        });
    }

    /** 
     * Retorna os dados do formulário de filtro
     * @param {boolean} asFormData - Define se os dados devem ser retornados como FormData
     * @returns {object | FormData} - Os dados do formulário
     */
    public getFilterFormData(asFormData?: boolean) {
        return getFormValues(this, asFormData);
    }

    /** 
     * Retorna os elementos do formulário de filtro
     * @returns {FormElement[]} - Array de elementos do formulário
     */
    public getFilterFormElements() {
        return getAllFormElements(this);
    }

    /** 
     * Retorna os dados do formulário de filtro agrupados por grupo
     * @returns {object[]} - Array de objetos contendo os dados do formulário agrupados por grupo
     */
    public getGroupedFilterData() {
        const filterGroups = this.getFilterGroups();

        const groupedFilterData: object[] = [];

        filterGroups.forEach((filterGroup: AtlasFilterGroup) => {
            groupedFilterData.push({ name: filterGroup.name, value: filterGroup.getFilterGroupData(false) });
        });

        return groupedFilterData;
    }

    /** 
     * Permite mesclar os dados de um formulário de filtro com os dados do formulário atual(utilizado para mesclar os dados de um formulário de filtro avançado com um formulário de filtro simples, e vice-versa)
     */
    public mergeFilterForm(formElements: Array<FormElement>) {
        this.getFilterGroups().forEach((filter: AtlasFilterGroup) => filter.mergeFilterGroup(formElements));
    }

    /** 
     * Reseta os dados do formulário de filtro
     */
    public resetFilterForm() {
        this.getFilterGroups().forEach((filter: AtlasFilterGroup) => filter.resetFilterData());
        this._filterHiddenInputs.forEach((input) => (input.value = input.defaultValue));
    }

    /**
     * Atualiza o contador de filtro
     */
    public countFilters() {
        const filterGroups = this.getFilterGroups();

        const counter = filterGroups.reduce((acc: number, filter: AtlasFilterGroup) => acc + filter.countFilters(), 0);

        emit(this, "atlas-filter-form-count", {
            detail: {
                counter
            },
            trackDisable: true
        });
    }

    private async onChangeCol1Slot() {
        await this.updateComplete;

        (this.shadowRoot.querySelector(".auto-col") as HTMLElement).style.display = "none";
        (this.shadowRoot.querySelector(".col-1") as HTMLElement).style.display = "flex";

        if (this.type === "advanced" || (this.type === "simple" && this._deviceController.isMobile)) {
            this.setCollapsibleGroups();
        }

        this.countFilters();
    }

    private async onChangeCol2Slot() {
        await this.updateComplete;

        (this.shadowRoot.querySelector(".auto-col") as HTMLElement).style.display = "none";
        (this.shadowRoot.querySelector(".col-2") as HTMLElement).style.display = "flex";

        if (this.type === "advanced" || (this.type === "simple" && this._deviceController.isMobile)) {
            this.setCollapsibleGroups();
        }

        this.countFilters();
    }

    private async onChangeCol3Slot() {
        await this.updateComplete;

        (this.shadowRoot.querySelector(".auto-col") as HTMLElement).style.display = "none";
        (this.shadowRoot.querySelector(".col-3") as HTMLElement).style.display = "flex";

        if (this.type === "advanced" || (this.type === "simple" && this._deviceController.isMobile)) {
            this.setCollapsibleGroups();
        }

        this.countFilters();
    }

    private async onChangeCol4Slot() {
        await this.updateComplete;

        (this.shadowRoot.querySelector(".auto-col") as HTMLElement).style.display = "none";
        (this.shadowRoot.querySelector(".col-4") as HTMLElement).style.display = "flex";

        if (this.type === "advanced" || (this.type === "simple" && this._deviceController.isMobile)) {
            this.setCollapsibleGroups();
        }

        this.countFilters();
    }

    private async onChangeMainSlot() {
        await this.updateComplete;

        this.shadowRoot.querySelectorAll(".col").forEach((col: HTMLElement) => {
            col.style.display = "none";
        });

        if (this.type === "advanced" || (this.type === "simple" && this._deviceController.isMobile)) {
            this.setCollapsibleGroups();
        }

        this.countFilters();

        this._filterHiddenInputs = this.getFilterHiddenInputs();
    }

    private setCollapsibleGroups() {
        const filterGroups = this.getFilterGroups();

        filterGroups.forEach((filterGroup: AtlasFilterGroup) => {
            if (filterGroup.header) {
                filterGroup.collapsible = true;
            }
        });
    }

    private openAdvancedFilter() {
        emit(this, "atlas-open-advanced-filter", {
            detail: {
                filterData: this.getFilterFormData(false),
                groupedFilterData: this.getGroupedFilterData()
            }
        });
    }

    private cleanFilter(event: Event) {
        const { target } = event;

        (target as AtlasButton).loading = true;
        this.disableButtons();

        emit(this, "atlas-clean-filter", {
            detail: {
                filterData: this.getFilterFormData(false),
                groupedFilterData: this.getGroupedFilterData()
            }
        });

        emit(this, "atlas-filter-close", { trackDisable: true });
    }

    private applyFilter(event: Event) {
        const { target } = event;

        (target as AtlasButton).loading = true;
        this.disableButtons();

        emit(this, "atlas-apply-filter", {
            detail: {
                filterData: this.getFilterFormData(false),
                groupedFilterData: this.getGroupedFilterData()
            }
        });

        emit(this, "atlas-filter-close", { trackDisable: true });
    }

    private getFilterGroups() {
        return [
            ...this._filterGroups,
            ...this._filterGroupsCol1,
            ...this._filterGroupsCol2,
            ...this._filterGroupsCol3,
            ...this._filterGroupsCol4
        ];
    }

    private getFilterHiddenInputs() {
        const slot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
        if (!slot) return [];

        return slot
            .assignedElements({ flatten: true })
            .filter((element) => INPUT_ELEMENTS.includes(element.tagName.toLowerCase()))
            .filter((input: AtlasInput) => input.type === "hidden") as AtlasInput[];
    }

    private renderFooter() {
        const advancedFilterButton = when(
            this.type === "simple" && this.hasAdvancedFilter,
            () => html`
                <atlas-button
                    description=${this.advancedFilterCounter > 0
                        ? `Filtros avançados (${this.advancedFilterCounter})`
                        : `Filtros avançados`}
                    theme="secondary"
                    type="outlined"
                    @atlas-button-click=${this.openAdvancedFilter}
                ></atlas-button>
            `
        );

        return html`
            <div class="atlas-filter-form-footer">
                <div>${advancedFilterButton}</div>
                <div class="actions-buttons">
                    <atlas-button
                        description="Limpar"
                        theme="secondary"
                        @atlas-button-click=${this.cleanFilter}
                    ></atlas-button>
                    <atlas-button description="Aplicar" @atlas-button-click=${this.applyFilter}></atlas-button>
                </div>
            </div>
        `;
    }

    /** @internal */
    public render() {
        const filterClass = {
            "atlas-filter-form": true,
            "atlas-advanced-filter-form": this.type === "advanced" || this._deviceController.isMobile
        };

        return html`
            <div class="${classMap(filterClass)}">
                <div class="atlas-filter-form-content">
                    <div class="auto-col">
                        <slot @slotchange=${this.onChangeMainSlot}></slot>
                    </div>

                    <div class="col col-1">
                        <slot name="col-1" @slotchange=${this.onChangeCol1Slot}> </slot>
                    </div>

                    <div class="col col-2">
                        <slot name="col-2" @slotchange=${this.onChangeCol2Slot}> </slot>
                    </div>

                    <div class="col col-3">
                        <slot name="col-3" @slotchange=${this.onChangeCol3Slot}> </slot>
                    </div>

                    <div class="col col-4">
                        <slot name="col-4" @slotchange=${this.onChangeCol4Slot}> </slot>
                    </div>
                </div>
                ${this.renderFooter()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-filter-form": AtlasFilterForm;
    }
}
