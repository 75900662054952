import type FormElement from "@/components/form/form-element";
import FieldValidator, { type FieldValidatorStatus } from "./field-validator";

const MESSAGES_MAP = {
    min: "O valor inserido deve ter no mínimo {{minlength}} {{label}}",
    max: "O valor inserido ultrapassa o limite de {{maxlength}} {{label}}",
    none: ""
};

export default class LengthValidator implements FieldValidator {
    name: string = "length";

    status: FieldValidatorStatus = "error";

    errorType: "min" | "max" | "none" = "none";

    reportOnChange: boolean = true;

    minlength: string;

    maxlength: string;

    getInvalidMessage(): string {
        return MESSAGES_MAP[this.errorType]
            .replace("{{maxlength}}", this.maxlength)
            .replace("{{minlength}}", this.minlength)
            .replace("{{label}}", this.getCharacterLabel());
    }

    validate(element: FormElement): boolean {
        this.resetState();

        const minlength = parseInt(element.getAttribute("minlength"), 10) ?? null;
        const maxlength = parseInt(element.getAttribute("maxlength"), 10) ?? null;
        const elementValueLength = String(element.getElementValue() ?? "").length;

        if (minlength && elementValueLength > 0 && elementValueLength < minlength) {
            this.errorType = "min";
            this.minlength = minlength.toString();
        }

        if (maxlength && elementValueLength > maxlength) {
            this.errorType = "max";
            this.maxlength = maxlength.toString();
        }

        return this.errorType === "none";
    }

    private resetState() {
        this.errorType = "none";
        this.minlength = "";
        this.maxlength = "";
    }

    private getCharacterLabel() {
        if (this.errorType === "none") return "";

        const valueToCompare = this.errorType === "max" ? this.maxlength : this.minlength;

        return valueToCompare === "1" ? "caractere" : "caracteres";
    }
}
