import FormElement from "@/components/form/form-element";
import { UploaderFile } from "@/internals/basic-types";

import type AtlasCheckbox from "@/components/form/atlas-checkbox/atlas-checkbox";
import type AtlasElementGroup from "@/components/layout/atlas-element-group/atlas-element-group";
import type AtlasDateRange from "@/components/form/atlas-date-range/atlas-date-range";
import type AtlasRadio from "@/components/form/atlas-radio/atlas-radio";

export const INPUT_ELEMENTS = [
    "atlas-card-number",
    "atlas-datepicker",
    "atlas-date-picker",
    "atlas-date-range",
    "atlas-float-input",
    "atlas-input",
    "atlas-integer-input",
    "atlas-masked-input",
    "atlas-money",
    "atlas-password-input",
    "atlas-percentage",
    "atlas-postal-code",
    "atlas-textarea"
];

export const CHECK_ELEMENTS = ["atlas-checkbox", "atlas-radio", "atlas-selection-card", "atlas-switch"];

export const SELECT_ELEMENTS = ["atlas-multiselect", "atlas-select", "atlas-toggle"];

const FORM_ELEMENTS = [
    "atlas-card-number",
    "atlas-checkbox",
    "atlas-datepicker",
    "atlas-date-picker",
    "atlas-date-range",
    "atlas-editor",
    "atlas-element-group",
    "atlas-float-input",
    "atlas-input",
    "atlas-integer-input",
    "atlas-masked-input",
    "atlas-money",
    "atlas-multiselect",
    "atlas-password-input",
    "atlas-percentage",
    "atlas-postal-code",
    "atlas-radio",
    "atlas-rating",
    "atlas-select",
    "atlas-selection-card",
    "atlas-switch",
    "atlas-textarea",
    "atlas-toggle",
    "atlas-uploader"
];

const belongsToAtlasElementGroup = (element: FormElement): boolean => {
    const atlasElementGroup = element.closest("atlas-element-group") as AtlasElementGroup;

    const isInsideAtlasElementGroup = atlasElementGroup !== null && !isAtlasElementGroup(element);

    return isInsideAtlasElementGroup && !!atlasElementGroup.name;
};

const isAtlasElementGroup = (element: FormElement): boolean => {
    return element.tagName === "ATLAS-ELEMENT-GROUP";
};

const isCheckboxElement = (element: FormElement): boolean => {
    const isCheckbox = element.tagName === "ATLAS-CHECKBOX";
    const isCardWithCheckbox =
        element.tagName === "ATLAS-SELECTION-CARD" && element.getAttribute("type") === "checkbox";

    return isCheckbox || isCardWithCheckbox;
};

const isRadioElement = (element: FormElement): boolean => {
    const isRadio = element.tagName === "ATLAS-RADIO";
    const isCardWithRadio = element.tagName === "ATLAS-SELECTION-CARD" && element.getAttribute("type") === "radio";

    return isRadio || isCardWithRadio;
};

const shouldGetValueCheckedAttribute = (element: FormElement): boolean => {
    const isCheckbox = isCheckboxElement(element);
    const isRadio = isRadioElement(element);

    if (!isCheckbox && !isRadio) return false;

    const belongsToAtlasFilter = element.closest("atlas-filter") !== null;
    if (belongsToAtlasFilter) return false;

    if (isRadio) return !element.value;

    return !element.value && !(element as AtlasCheckbox).indeterminate;
};

const appendValuesToFormData = (formData: FormData, element: FormElement, elementValue: any) => {
    if (formData.has(element.name) && elementValue === "") {
        return;
    }

    const isCheckbox = isCheckboxElement(element);
    const isRadio = isRadioElement(element);
    const isUploader = element.tagName === "ATLAS-UPLOADER";
    const isDateRange = element.tagName === "ATLAS-DATE-RANGE";

    if (shouldGetValueCheckedAttribute(element)) {
        const checkedElement = element as AtlasCheckbox | AtlasRadio;
        formData.append(element.name, checkedElement.checked.toString());
        return;
    }

    if ((isRadio || isCheckbox) && !(element as AtlasCheckbox).checked) {
        return;
    }

    if (isUploader) {
        const uploadedFiles = elementValue as Array<UploaderFile>;

        uploadedFiles.forEach((file) => {
            formData.append(`${element.name}[tempFileId]`, `${file.tempFileId}`);
            formData.append(`${element.name}[tempFileName]`, `${file.tempFileName}`);
        });

        return;
    }

    if (isDateRange) {
        const dateRangeElement = element as AtlasDateRange;
        const { start, end } = dateRangeElement.getSelectedRange();

        formData.append(dateRangeElement.getInputName("start"), start);
        formData.append(dateRangeElement.getInputName("end"), end);
        return;
    }

    formData.append(element.name, elementValue);
};

const appendValuesToObject = (object: { [key: string]: any }, element: FormElement, elementValue: any) => {
    const isCheckbox = isCheckboxElement(element);
    const isRadio = isRadioElement(element);
    const isDateRange = element.tagName === "ATLAS-DATE-RANGE";
    const key = element.name;

    if (shouldGetValueCheckedAttribute(element)) {
        const checkedElement = element as AtlasCheckbox | AtlasRadio;
        Object.assign(object, { [key]: checkedElement.checked });
        return;
    }

    if ((isRadio || isCheckbox) && !(element as AtlasCheckbox).checked) {
        return;
    }

    if (!object[key] && !isCheckbox && !isRadio) {
        if (isDateRange) {
            const dateRangeElement = element as AtlasDateRange;
            const { start, end } = dateRangeElement.getSelectedRange();

            Object.assign(object, { [dateRangeElement.getInputName("start")]: start });
            Object.assign(object, { [dateRangeElement.getInputName("end")]: end });
            return;
        }

        Object.assign(object, { [key]: elementValue });
        return;
    }

    if ((isCheckbox || isRadio) && typeof object[key] === "undefined") {
        Object.assign(object, { [key]: elementValue });
        return;
    }

    if (!Array.isArray(object[key])) {
        Object.assign(object, {
            [key]: [object[key]]
        });
    }

    Object.assign(object, {
        [key]: [...object[key], elementValue]
    });
};

const getFormElementsFromContainer = (container: HTMLElement | Element): Array<FormElement> => {
    const formElements = container.querySelectorAll(FORM_ELEMENTS.toString());

    return [].concat(...formElements);
};

const getFormElementsFromSlot = (slot: HTMLSlotElement): Array<FormElement> => {
    const slottedElements = slot.assignedElements({ flatten: true }) as HTMLElement[];
    const slottedFormElements: Array<FormElement> = [];

    slottedElements.forEach((slottedElement) => {
        if (FORM_ELEMENTS.includes(slottedElement.tagName.toLowerCase())) {
            slottedFormElements.push(slottedElement as FormElement);
        } else {
            slottedFormElements.push(...getFormElementsFromContainer(slottedElement));
        }
    });

    return slottedFormElements;
};

export const getAllFormElements = (container?: string | HTMLElement): Array<FormElement> => {
    let containerElement;

    if (container instanceof HTMLElement) {
        containerElement = container;
    } else if (container) {
        containerElement = document.querySelector(container);
    } else {
        containerElement = document.body;
    }

    const elements = getFormElementsFromContainer(containerElement);

    containerElement.querySelectorAll("slot").forEach((slotElement: HTMLSlotElement) => {
        elements.push(...getFormElementsFromSlot(slotElement));
    });

    return elements;
};

export const getFormValues = (container?: string | HTMLElement, asFormData?: boolean): FormData | object => {
    const formData = asFormData ? new FormData() : {};

    const formElements = getAllFormElements(container).filter((element) => {
        if (isAtlasElementGroup(element)) return !!element.name;

        return !belongsToAtlasElementGroup(element);
    });

    formElements.forEach((element) => {
        const value = element.getElementValue?.();

        if (asFormData) {
            appendValuesToFormData(formData as FormData, element, value);
        } else {
            appendValuesToObject(formData, element, value);
        }
    });

    return formData;
};

export const checkFormValidity = (container?: string | HTMLElement): boolean => {
    const formElements = getAllFormElements(container);

    return formElements.every((element) => (element.checkValidity ? element.checkValidity() : false));
};

export const reportFormValidity = (container?: string | HTMLElement): boolean => {
    const formElements = getAllFormElements(container);
    let hasFocusOnFirstInvalidElement = false;
    let hasInvalidElement = false;

    formElements.forEach((element) => {
        if (!element.reportValidity || !element.reportValidity()) {
            hasInvalidElement = true;

            if (!hasFocusOnFirstInvalidElement) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                });

                setTimeout(() => {
                    element.focus({ preventScroll: false });
                }, 500);

                hasFocusOnFirstInvalidElement = true;
            }
        }
    });

    return !hasInvalidElement;
};

export const resetFormData = (container?: string | HTMLElement) => {
    const formElements = getAllFormElements(container);

    formElements.forEach((element) => {
        element.reset();
    });
};

export const syncFormElementsInitialValue = (container?: string | HTMLElement) => {
    const formElements = getAllFormElements(container);

    formElements.forEach((element) => {
        element.syncInitialValue();
    });
};
