import {css} from "lit-element/lit-element.js";
export default css`.section-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

::slotted(atlas-section) {
  margin-bottom: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjMzUxMTQzNjI3NS9zcmMvcGFja2FnZXMvd2ViY29tcG9uZW50cy9zcmMvY29tcG9uZW50cy9sYXlvdXQvYXRsYXMtc2VjdGlvbi1ncm91cCIsInNvdXJjZXMiOlsiYXRsYXMtc2VjdGlvbi1ncm91cC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0k7RUFDQTtFQUNBOzs7QUFHSjtFQUNJIiwic291cmNlc0NvbnRlbnQiOlsiLnNlY3Rpb24tZ3JvdXAge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBnYXA6IDAuNXJlbTtcbn1cblxuOjpzbG90dGVkKGF0bGFzLXNlY3Rpb24pIHtcbiAgICBtYXJnaW4tYm90dG9tOiAwO1xufVxuIl19 */`;
