import { CookieAttributes } from "js-cookie";

type Cookie = {
    name: string;
    defaultValue?: any;
    config?: CookieAttributes;
};

export const SIDEBAR_COLLAPSE_COOKIE: Cookie = {
    name: "atlas_sidebar_collapse_1734448768",
    config: {
        expires: 365,
        sameSite: "strict"
    }
};

export default { SIDEBAR_COLLAPSE_COOKIE };
