export class AtlasRequestError extends Error {
    response: Response;
    status: number;
    constructor(message: string, response: Response) {
        super(message);

        this.name = 'AtlasRequestError';
        this.response = response;
        this.status = response.status;
    }
}