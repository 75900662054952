import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { filterEmptyNodes } from "@/internals/elements";
import { Theme } from "@/internals/theme";

import AtlasElement from "@/components/atlas-element";
import styles from "./atlas-helper-text.scss";
import "@/components/display/atlas-icon/atlas-icon";

type CriticalityOptions = "default" | "warning";
type Size = "lg" | "md" | "sm";

/**
 * @dependency atlas-icon
 *
 * @tag atlas-helper-text
 */
@customElement("atlas-helper-text")
export default class AtlasHelperText extends AtlasElement {
    static styles = styles;

    /**
     * Ícone que irá aparecer ao lado do helper-text
     * @deprecated
     */
    @property({ type: String }) icon: string;

    /**
     * Tema do ícone
     * @deprecated
     */
    @property({ type: String, attribute: "icon-theme" }) iconTheme: Theme;

    /** Tipo de criticidade do helper-text alterando o ícone e sua cor */
    @property({ type: String }) criticality: CriticalityOptions;

    /** Tamanho da tipografia e ícone */
    @property({ type: String }) size: Size = "sm";

    @property({ type: Boolean, attribute: "is-toggle-helper" }) isToggleHelper = false;

    @property({ type: Boolean }) white: boolean = false;

    @state() private _hasSlottedContent = true;

    onChangeContent() {
        const slottedNodes = this.shadowRoot.querySelector("slot").assignedNodes({ flatten: true });
        const filteredNodes = filterEmptyNodes(slottedNodes);

        this._hasSlottedContent = filteredNodes.length > 0;
    }

    getIconTheme() {
        const themes = {
            default: "secondary",
            warning: "warning"
        };

        return themes[this.criticality] ?? this.iconTheme;
    }

    getIcon() {
        const icons = {
            default: "alert-circle",
            warning: "alert-triangle"
        };

        return icons[this.criticality] ?? this.icon;
    }

    getTextSize(): Size {
        const sizes = {
            lg: "md",
            md: "sm",
            sm: "xs"
        };

        return sizes[this.size] as Size;
    }

    renderIcon() {
        const iconSize = this.size === "lg" ? "3x" : "2x";

        return when(
            this.criticality || this.icon,
            () => html`<atlas-icon name=${this.getIcon()} theme=${this.getIconTheme()} size=${iconSize}></atlas-icon>`
        );
    }

    render() {
        const helperTextClass = {
            "form-text": true,
            "form-text-toggle": this.isToggleHelper,
            "empty-helper": !this._hasSlottedContent
        };

        return html`
            <div id="${this.id}-helper" class="${classMap(helperTextClass)}">
                ${this.renderIcon()}
                <atlas-text size=${this.getTextSize()} muted ?white=${this.white}>
                    <slot @slotchange=${this.onChangeContent}></slot>
                </atlas-text>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-helper-text": AtlasHelperText;
    }
}
