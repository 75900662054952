import {css} from "lit-element/lit-element.js";
export default css`:host {
  display: none;
  width: 100%;
}

:host([visible]) {
  display: inline-flex;
}

.step-content {
  display: block;
  width: 100%;
}
.step-content:not(.is-modal-wizard).off-step {
  margin-top: 0.5rem;
}

::slotted(atlas-section:last-child) {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .step-content:not(.is-modal-wizard).off-step {
    margin-top: 2rem;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjMzUxMTQzNjI3NS9zcmMvcGFja2FnZXMvd2ViY29tcG9uZW50cy9zcmMvY29tcG9uZW50cy93aXphcmQvYXRsYXMtd2l6YXJkLXN0ZXAiLCJzb3VyY2VzIjpbImF0bGFzLXdpemFyZC1zdGVwLnNjc3MiLCIuLi8uLi8uLi9zdHlsZXMvbWl4aW5zL19icmVha3BvaW50cy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0k7RUFDQTs7O0FBR0o7RUFDSTs7O0FBR0o7RUFDSTtFQUNBOztBQUVBO0VBQ0k7OztBQUlSO0VBQ0k7OztBQ1JBO0VEWUE7SUFDSSIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgXCIuLi8uLi8uLi9zdHlsZXMvbWl4aW5zL2JyZWFrcG9pbnRzXCI7XG5cbjpob3N0IHtcbiAgICBkaXNwbGF5OiBub25lO1xuICAgIHdpZHRoOiAxMDAlO1xufVxuXG46aG9zdChbdmlzaWJsZV0pIHtcbiAgICBkaXNwbGF5OiBpbmxpbmUtZmxleDtcbn1cblxuLnN0ZXAtY29udGVudCB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgd2lkdGg6IDEwMCU7XG5cbiAgICAmOm5vdCguaXMtbW9kYWwtd2l6YXJkKS5vZmYtc3RlcCB7XG4gICAgICAgIG1hcmdpbi10b3A6IDAuNXJlbTtcbiAgICB9XG59XG5cbjo6c2xvdHRlZChhdGxhcy1zZWN0aW9uOmxhc3QtY2hpbGQpIHtcbiAgICBtYXJnaW4tYm90dG9tOiAwO1xufVxuXG5AaW5jbHVkZSBicmVha3BvaW50cy5icmVha3BvaW50LXVwLWxnIHtcbiAgICAuc3RlcC1jb250ZW50Om5vdCguaXMtbW9kYWwtd2l6YXJkKS5vZmYtc3RlcCB7XG4gICAgICAgIG1hcmdpbi10b3A6IDJyZW07XG4gICAgfVxufVxuIiwiQG1peGluIGJyZWFrcG9pbnQtdXAtc20ge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDU3NnB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtdXAtbWQge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtdXAtbGcge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDk5MnB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtdXAteGwge1xuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDEyMDBweCkge1xuICAgICAgICBAY29udGVudDtcbiAgICB9XG59XG5cbkBtaXhpbiBicmVha3BvaW50LXVwLXh4bCB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogMTQwMHB4KSB7XG4gICAgICAgIEBjb250ZW50O1xuICAgIH1cbn1cblxuQG1peGluIGJyZWFrcG9pbnQtZG93bi1sZyB7XG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogOTkycHgpIHtcbiAgICAgICAgQGNvbnRlbnQ7XG4gICAgfVxufVxuIl19 */`;
