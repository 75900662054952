import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { ifDefined } from "lit/directives/if-defined.js";

import { showAlert } from "@/helpers/notifications";
import { get } from "@/helpers/request";
import { emit } from "@/internals/events";
import { TableColumn } from "./types";
import { TableSort } from "../atlas-table/types";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-easy-table.scss";
import "@/components/display/atlas-empty-state/atlas-empty-state";
import "@/components/table/atlas-table/atlas-table";
import "@/components/table/atlas-table-header/atlas-table-header";
import "@/components/table/atlas-table-body/atlas-table-body";
import "@/components/table/atlas-table-footer/atlas-table-footer";
import "@/components/table/atlas-table-col/atlas-table-col";

export type EasyTableProps = AtlasElementProps & {
    "selectable": boolean;
    "has-actions": boolean;
    "hide-footer": boolean;
    "hide-footer-text": boolean;
    "search-on-render": boolean;
    "url": string;
    "params": object;
    "items-per-page": number;
    "current-page": number;
    "total-records": number;
    "footer-text": string;
    "columns": TableColumn[];
    "empty-state-icon": string;
    "empty-state-header": string;
    "empty-state-description": string;
    "sequenced-pagination": boolean;
    "enable-line-break": boolean;
};

/**
 * @dependency atlas-empty-state
 * @dependency atlas-table
 * @dependency atlas-table-header
 * @dependency atlas-table-body
 * @dependency atlas-table-footer
 * @dependency atlas-table-col
 *
 * @prop {boolean} selectable - Indica se a table permite múltipla seleção
 * @prop {boolean} has-actions - Indica se a table tem ações
 * @prop {boolean} hide-footer - Indica se o rodapé com a paginação deve ser escondido
 * @prop {boolean} hide-footer-text - Indica se o texto do rodapé deve ser escondido
 * @prop {boolean} search-on-render - Indica se a pesquisa deve ser feita já ao renderizar a table
 * @prop {string} url - URL do endpoint que irá buscar as linhas da table
 * @prop {object} params - Parâmetros que serão enviados para o backend
 * @prop {number} items-per-page - Número de itens que serão carregados por página
 * @prop {number} current-page - Página atual da listagem
 * @prop {number} total-records - Total de registros que a listagem possui (incluindo todas as páginas)
 * @prop {string} footer-text - Texto que aparece no rodapé, no lugar da informação do número de registros da página
 * @prop {string} content-name - Nome do conteúdo que será exibido na listagem
 * @prop {string} content-name-plural - Nome do conteúdo no plural que será exibido na listagem
 * @prop {TableColumn[]} columns - Array com as configurações das colunas, os atributos `name` e `label` são requeridos para todas as colunas, os atributos `size`, `ellipsis` e `sortable` são opcionais
 * @prop {string} empty-state-icon - Ícone do empty-state que é exibido quando a listagem está vazia
 * @prop {string} empty-state-header - Título do empty-state que é exibido quando a listagem está vazia
 * @prop {string} empty-state-description - Descrição do empty-state que é exibido quando a listagem está vazia
 * @prop {boolean} sequenced-pagination - Indica se a paginação deve ser sequencial(apenas próximo e anterior)
 * @prop {boolean} enable-line-break - Indica se a quebra de linha deve ser habilitada
 *
 * @event {CustomEvent} atlas-table-before-search - Evento disparado logo antes de uma pesquisa ser feita
 * @event {CustomEvent} atlas-table-after-search - Evento disparado logo após uma pesquisa ser feita
 *
 * @slot "" - No slot padrão é possível passar as linhas do primeiro carregamento, para que seja feito de forma síncrona, sem bater novamente no backend
 * @slot empty-state-template - Nesse slot é possível informar o template do empty-state, permitindo enviar um empty-state mais customizável
 *
 * @tag atlas-easy-table
 */
@customElement("atlas-easy-table")
export default class AtlasEasyTable extends AtlasElement {
    static styles = styles;

    @property({ type: Boolean }) selectable: boolean;

    @property({ type: Boolean, attribute: "has-actions" }) hasActions: boolean;

    @property({ type: Boolean, attribute: "hide-footer" }) hideFooter: boolean;

    @property({ type: Boolean, attribute: "hide-footer-text" }) hideFooterText: boolean;

    @property({ type: Boolean, attribute: "search-on-render" }) searchOnRender: boolean;

    @property({ type: String }) url: string;

    @property({ type: Object }) params: { [key: string]: any } = {};

    @property({ type: Number, attribute: "items-per-page" }) itemsPerPage = 10;

    @property({ type: Number, attribute: "current-page", reflect: true }) currentPage = 1;

    @property({ type: Number, attribute: "total-records", reflect: true }) totalRecords = 0;

    @property({ type: String, attribute: "footer-text", reflect: true }) footerText: string;

    @property({ type: String, attribute: "content-name" }) contentName: string;

    @property({ type: String, attribute: "content-name-plural" }) contentNamePlural: string;

    @property({ type: Array }) columns: TableColumn[] = [];

    @property({ type: String, attribute: "empty-state-icon" }) emptyStateIcon: string;

    @property({ type: String, attribute: "empty-state-header" }) emptyStateHeader: string;

    @property({ type: String, attribute: "empty-state-description" }) emptyStateDescription: string;

    @property({ type: String, attribute: "sorted-column" }) sortedColumn: string;

    @property({ type: String, attribute: "sorted-order" }) sortedOrder: TableSort;

    @property({ type: Boolean, attribute: "sequenced-pagination" }) sequencedPagination: boolean;

    @property({ type: Boolean, attribute: "enable-line-break" }) enableLineBreak: boolean = false;

    connectedCallback(): void {
        super.connectedCallback?.();

        if (!this.currentPage || isNaN(this.currentPage)) this.currentPage = 1;

        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.addEventListener("atlas-table-page-change", this.onPageChange);
        this.addEventListener("atlas-table-change-sorting", this.onSortChange);

        if (this.searchOnRender) {
            setTimeout(() => {
                this.fetchRecords();
            }, 1000);
        }
    }

    disconnectedCallback(): void {
        this.removeEventListener("atlas-table-page-change", this.onPageChange);
        this.removeEventListener("atlas-table-change-sorting", this.onSortChange);
    }

    async fetchRecords(shouldGoToFirstPage?: boolean, previousPage?: number, recordWasRemoved?: boolean) {
        await this.updateComplete;

        this.skeletonLoading = true;
        emit(this, "atlas-table-before-search");

        setTimeout(() => {
            const params = this.getFetchParams();

            const totalRecords = recordWasRemoved ? this.totalRecords - 1 : this.totalRecords;
            const totalPages = Math.ceil(totalRecords / this.itemsPerPage);
            const shouldGoToPreviousPage = recordWasRemoved && this.currentPage > 1 && this.currentPage > totalPages;

            if (shouldGoToFirstPage) {
                params.page = 1;
            } else if (shouldGoToPreviousPage) {
                params.page = this.currentPage - 1;
            }

            get(this.url, params).then((response) => {
                if (!response.success) {
                    this.onFetchError(response, previousPage);
                    return;
                }

                const defaultSlot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
                defaultSlot.assignedElements({ flatten: true }).forEach((element) => element.remove());

                this.innerHTML += response.content;
                this.totalRecords = response.totalRecords;

                if (shouldGoToFirstPage) {
                    this.currentPage = 1;
                } else if (shouldGoToPreviousPage) {
                    this.currentPage -= 1;
                }

                setTimeout(() => {
                    this.skeletonLoading = false;

                    emit(this, "atlas-table-after-search", {
                        detail: { response }
                    });
                }, 1000);
            });
        }, 500);
    }

    getFetchParams() {
        return {
            ...this.params,
            page: this.currentPage,
            itemsPerPage: this.itemsPerPage,
            ...this.getSortingParams()
        };
    }

    getSortingParams() {
        if (!this.sortedColumn || !this.sortedOrder || this.sortedOrder === "none") return {};

        return {
            sort: this.sortedColumn,
            order: this.sortedOrder
        };
    }

    onPageChange(event: CustomEvent) {
        const { page, previousPage } = event.detail;

        this.currentPage = page;
        this.fetchRecords(false, previousPage);
    }

    onSortChange(event: CustomEvent) {
        const { column, order } = event.detail;

        this.sortedColumn = column;
        this.sortedOrder = order;
        this.fetchRecords();
    }

    onFetchError(response?: any, previousPage?: number) {
        const feedbackMessage = response.message || "Não foi possível carregar a listagem, tente novamente.";

        if (previousPage) this.currentPage = previousPage;
        this.skeletonLoading = false;
        showAlert(feedbackMessage, "ERROR");

        emit(this, "atlas-table-after-search", {
            detail: { response }
        });
    }

    renderHeaderContent() {
        return this.columns.map(
            (column) => html`
                <atlas-table-col
                    name=${column.name}
                    size=${column.size || "md"}
                    ?sortable=${column.sortable}
                    ?ellipsis=${column.ellipsis}
                    sort=${column.name === this.sortedColumn ? this.sortedOrder || "none" : "none"}
                    popover-title=${ifDefined(column.popoverTitle)}
                    popover-content=${ifDefined(column.popoverContent)}
                >
                    ${column.label}
                </atlas-table-col>
            `
        );
    }

    renderTable() {
        return html`
            <atlas-table
                ?selectable=${this.selectable}
                ?has-actions=${this.hasActions}
                ?enable-line-break=${this.enableLineBreak}
            >
                <atlas-table-header slot="header">${this.renderHeaderContent()}</atlas-table-header>
                <atlas-table-body slot="body">
                    <slot></slot>
                </atlas-table-body>
                <atlas-table-footer
                    slot="footer"
                    items-per-page=${this.itemsPerPage}
                    current-page=${this.currentPage}
                    total-items=${this.totalRecords}
                    footer-text=${this.footerText}
                    content-name=${this.contentName}
                    content-name-plural=${this.contentNamePlural}
                    ?hide-footer-text=${this.hideFooterText}
                    ?hidden=${this.hideFooter}
                    ?sequenced-pagination=${this.sequencedPagination}
                ></atlas-table-footer>
            </atlas-table>
        `;
    }

    renderEmptyState() {
        return when(
            !this.emptyStateIcon || !this.emptyStateHeader || !this.emptyStateDescription,
            () => html`<slot name="empty-state-template"></slot>`,
            () => html`
                <atlas-empty-state
                    icon=${this.emptyStateIcon}
                    header=${this.emptyStateHeader}
                    description=${this.emptyStateDescription}
                ></atlas-empty-state>
            `
        );
    }

    render() {
        return html`
            <div class="easy-table">
                ${when(
                    this.totalRecords > 0 || this.skeletonLoading,
                    () => this.renderTable(),
                    () => this.renderEmptyState()
                )}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-easy-table": AtlasEasyTable;
    }
}
