import { LitElement, html } from "lit";
import { customElement, property, queryAssignedElements } from "lit/decorators.js";

import DeviceController from "@/controllers/device-controller";

import AtlasNavbar from "@/components/structure/atlas-navbar/atlas-navbar";
import AtlasSidebar from "@/components/structure/atlas-sidebar/atlas-sidebar";

import style from "./atlas-screen.scss";

export type ScreenProps = {
    fullscreen: boolean;
};

/**
 * @slot navbar - Slot usado para incluir uma navbar
 * @slot sidebar - Slot usado para incluir uma sidebar
 * @slot - Slot padrão usado para incluir conteúdos no corpo da tela
 */
@customElement("atlas-screen")
export default class AtlasScreen extends LitElement {
    static styles = style;

    @property({ type: Boolean, reflect: true }) fullscreen: boolean;

    @queryAssignedElements({ selector: "atlas-navbar", flatten: true, slot: "navbar" })
    private slottedNavbar: Array<AtlasNavbar>;

    @queryAssignedElements({ selector: "atlas-sidebar", flatten: true, slot: "sidebar" })
    private slottedSidebar: Array<AtlasSidebar>;

    private deviceController = new DeviceController(this, this.onChangeScreenType.bind(this));

    connectedCallback() {
        super.connectedCallback?.();

        this.onSidebarToggleClick = this.onSidebarToggleClick.bind(this);
        this.onMobileSidebarToggle = this.onMobileSidebarToggle.bind(this);
        this.addEventListener("atlas-sidebar-toggle-click", this.onSidebarToggleClick);
        this.addEventListener("atlas-sidebar-toggle", this.onMobileSidebarToggle);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-sidebar-toggle-click", this.onSidebarToggleClick);
        this.removeEventListener("atlas-sidebar-toggle", this.onMobileSidebarToggle);
    }

    getSlottedSidebar() {
        return this.slottedSidebar?.[0];
    }

    getSlottedNavbar() {
        return this.slottedNavbar?.[0];
    }

    onChangeScreenType() {
        this.onMobileSidebarToggle();
    }

    onSidebarToggleClick() {
        this.getSlottedSidebar().onToggleCollapse();
        window.scroll(0, 0);
    }

    onMobileSidebarToggle() {
        const sidebar = this.getSlottedSidebar();

        if (sidebar) {
            setTimeout(() => {
                document.body.classList.toggle("disable-scroll", this.deviceController.isMobile && !sidebar.collapsed);
            }, 0);
        }
    }

    render() {
        return html`
            <div class="screen-wrapper">
                <div class="screen-sidebar">
                    <slot name="sidebar"></slot>
                </div>
                <div class="screen-content">
                    <div class="screen-navbar">
                        <slot name="navbar"></slot>
                    </div>
                    <div class="screen-body">
                        <slot></slot>
                    </div>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-screen": AtlasScreen;
    }
}
