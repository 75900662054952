import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import DeviceController from "@/controllers/device-controller";
import { Watch } from "@/decorators/watch";

import type AtlasTableHeader from "@/components/table/atlas-table-header/atlas-table-header";
import type AtlasTableBody from "@/components/table/atlas-table-body/atlas-table-body";
import type AtlasTableRow from "../atlas-table-row/atlas-table-row";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table.scss";

export type TableProps = AtlasElementProps & {
    "has-actions": boolean;
    "selectable": boolean;
    "enable-line-break": boolean;
};

/**
 *
 * @slot header - Slot para colocar o cabeçalho da table
 * @slot body - Slot para colocar o corpo da table
 * @slot footer - Slot para colocar o rodapé da table
 *
 * @tag atlas-table
 */
@customElement("atlas-table")
export default class AtlasTable extends AtlasElement {
    static styles = styles;

    /** Indica se a table permite múltipla seleção */
    @property({ type: Boolean }) selectable = false;

    /** Indica se a table tem ações */
    @property({ type: Boolean, attribute: "has-actions" }) hasActions = false;

    /** Indica se a table deve quebrar linhas longas */
    @property({ type: Boolean, attribute: "enable-line-break" }) enableLineBreak: boolean = false;

    @query(".table-wrapper") private _tableWrapper: HTMLElement;

    @query(".table-scroll") private _tableScroll: HTMLElement;

    @state() private _hasFooter = false;

    private _deviceController = new DeviceController(this);

    private _tableWrapperResizeObserver: ResizeObserver;

    private _tableScrollResizeObserver: ResizeObserver;

    private _tableWrapperHeight: number | null = null;

    private _hasScroll: boolean | null = null;

    public connectedCallback() {
        super.connectedCallback?.();

        this.adjustRowFadePosition = this.adjustRowFadePosition.bind(this);
        this.adjustRowFadeVisibility = this.adjustRowFadeVisibility.bind(this);
        this.onTableWrapperResize = this.onTableWrapperResize.bind(this);
        this.onTableScrollResize = this.onTableScrollResize.bind(this);
        this.applyColumnPropsFromSync = this.applyColumnPropsFromSync.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onTableRowSelectionDisabledChange = this.onTableRowSelectionDisabledChange.bind(this);

        this.addEventListener("atlas-table-header-sync-cols", this.applyColumnPropsFromSync);
        this.addEventListener("atlas-table-select-all", this.onSelectAll);
        this.addEventListener("atlas-table-row-select", this.onSelectRow);
        this.addEventListener("atlas-table-row-selection-disabled-change", this.onTableRowSelectionDisabledChange);

        this._deviceController.setScreenChangeCallback(this.adjustRowFadeVisibility);
        this._tableWrapperResizeObserver = new ResizeObserver(this.onTableWrapperResize);
        this._tableScrollResizeObserver = new ResizeObserver(this.onTableScrollResize);

        this.updateComplete.then(() => {
            this._tableWrapperResizeObserver.observe(this._tableWrapper);
            this._tableScrollResizeObserver.observe(this._tableScroll);
        });
    }

    public disconnectedCallback() {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-table-header-sync-cols", this.applyColumnPropsFromSync);
        this.removeEventListener("atlas-table-select-all", this.onSelectAll);
        this.removeEventListener("atlas-table-row-select", this.onSelectRow);
        this.removeEventListener("atlas-table-row-selection-disabled-change", this.onTableRowSelectionDisabledChange);

        this._tableWrapperResizeObserver.disconnect();
        this._tableScrollResizeObserver.disconnect();
    }

    private getSlottedHeader(): AtlasTableHeader {
        const headerSlot = this.shadowRoot.querySelector("slot[name=header]") as HTMLSlotElement;
        const slottedElements = headerSlot?.assignedElements() ?? [];

        return (slottedElements[0] as AtlasTableHeader) || null;
    }

    private getSlottedBody(): AtlasTableBody {
        const bodySlot = this.shadowRoot.querySelector("slot[name=body]") as HTMLSlotElement;
        const slottedElements = bodySlot?.assignedElements() ?? [];

        return (slottedElements[0] as AtlasTableBody) || null;
    }

    private onSlotChangeFooter() {
        const footerSlot = this.shadowRoot.querySelector("slot[name=footer]") as HTMLSlotElement;
        const footerContent = footerSlot.assignedElements();

        this._hasFooter = footerContent.length > 0;
    }

    private onTableWrapperResize(entries: ResizeObserverEntry[]) {
        entries.forEach((entry) => {
            const tableWrapperNewHeight = entry.contentRect.height;
            if (this._tableWrapperHeight === tableWrapperNewHeight) return;

            this._tableWrapperHeight = tableWrapperNewHeight;
            this.adjustRowFadePosition();
        });
    }

    private onTableScrollResize(entries: ResizeObserverEntry[]) {
        entries.forEach((entry) => {
            const entryHasScroll = entry.target.scrollWidth > entry.target.clientWidth;
            if (this._hasScroll === entryHasScroll) return;

            this._hasScroll = entryHasScroll;
            this.adjustRowFadeVisibility();
        });
    }

    private async onTableRowSelectionDisabledChange(event: CustomEvent) {
        const { selectionDisabled } = event.detail;

        const tableHeader = this.getSlottedHeader();

        if (!selectionDisabled) {
            tableHeader?.toggleAttribute("selection-disabled", false);
            return;
        }

        const slottedRows = (await this.getSlottedBody()?.getSlottedRows()) ?? [];

        const isAllSlottedRowsSelectionDisabled = slottedRows.every((row: AtlasTableRow) => row.selectionDisabled);

        tableHeader?.toggleAttribute("selection-disabled", isAllSlottedRowsSelectionDisabled);
    }

    /**
     * @internal
     */
    @Watch(["selectable", "hasActions", "enableLineBreak"])
    public async applyPropsInSlots() {
        await this.updateComplete;

        const tableHeader = this.getSlottedHeader();
        const tableBody = this.getSlottedBody();

        tableHeader?.toggleAttribute("selectable", this.selectable);
        tableHeader?.toggleAttribute("has-actions", this.hasActions);

        tableBody?.toggleAttribute("selectable", this.selectable);
        tableBody?.toggleAttribute("has-actions", this.hasActions);
        tableBody?.toggleAttribute("enable-line-break", this.enableLineBreak);
    }

    private async applyColumnPropsFromSync(event: CustomEvent) {
        await this.updateComplete;

        const tableBody = this.getSlottedBody();
        const colsProps: any[] = event.detail;

        tableBody.applyPropsFromHeader(colsProps);
    }

    private getTableScrollMarginX(side: "left" | "right") {
        const marginStyleKey = side === "left" ? "marginLeft" : "marginRight";
        const margin = getComputedStyle(this._tableScroll)[marginStyleKey];

        return -1 * parseFloat(margin.toString().replace("px", ""));
    }

    private async adjustRowFadeVisibility() {
        await this.updateComplete;

        if (this.skeletonLoading || this._deviceController.isMobile) return;

        const tableBody = this.getSlottedBody();
        const tableRows = (await tableBody?.getSlottedRows()) as AtlasTableRow[];
        if (tableRows.length === 0) return;

        const { scrollLeft, clientWidth, scrollWidth } = this._tableScroll;
        const marginLeft = this.getTableScrollMarginX("left");
        const marginRight = this.getTableScrollMarginX("right");

        tableRows.forEach((tableRow) => {
            tableRow.toggleLeftFade(scrollLeft > marginLeft);
            tableRow.toggleRightFade(scrollLeft + clientWidth < scrollWidth - marginRight);
        });
    }

    private async adjustRowFadePosition() {
        await this.updateComplete;

        if (this.skeletonLoading || this._deviceController.isMobile) return;

        const tableBody = this.getSlottedBody();
        const tableRows = (await tableBody?.getSlottedRows()) as AtlasTableRow[];

        tableRows.forEach((tableRow) => {
            tableRow.adjustRowFade(this._tableWrapper.getBoundingClientRect().top);
        });
    }

    private async onSelectAll(event: CustomEvent) {
        const tableBody = this.getSlottedBody();
        const allSelected = event.detail;

        (await tableBody.getSlottedRows()).forEach((row: AtlasTableRow) => {
            row.toggleSelection(allSelected);
        });
    }

    private async onSelectRow() {
        const tableBody = this.getSlottedBody();
        const allSelected = (await tableBody.getSlottedRows()).every((row: AtlasTableRow) => row.selected);

        this.getSlottedHeader().allSelected = allSelected;
    }

    public render() {
        const tableClass = {
            "atlas-table": true,
            "has-footer": this._hasFooter
        };

        return html`
            <div class="table-wrapper">
                <div class="table-scroll" @scroll=${this.adjustRowFadeVisibility}>
                    <div class=${classMap(tableClass)}>
                        <div class="table-header">
                            <slot name="header"></slot>
                        </div>
                        <div class="table-body">
                            <slot name="body"></slot>
                        </div>
                    </div>
                </div>
                <slot name="footer" @slotchange=${this.onSlotChangeFooter}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table": AtlasTable;
    }
}
