import { html, HTMLTemplateResult, LitElement } from "lit";
import { property, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";

import { isEmptySlot } from "@/internals/slot";
import type { Constructor } from "@/internals/util-types";

import type { TextSize } from "@/internals/size";

import "@/components/display/atlas-text/atlas-text";

type RenderDescriptionOptions = {
    size?: TextSize;
    wrapperClass?: string;
};

export type WithDescriptionProps = {
    description: string;
};

export declare class WithDescriptionInterface {
    description: string;

    renderDescription(options?: RenderDescriptionOptions): HTMLTemplateResult;
}

export const WithDescriptionMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    /**
     * @dependency atlas-text
     *
     * @slot description - Slot onde pode ser incluída a descrição do componente - o atributo `description` será priorizado
     */
    class WithVisibilityIconClass extends superClass {
        /** A descrição exibida abaixo do título */
        @property({ type: String }) description: string;

        @state() protected _hasSlottedDescription = false;

        /** @internal */
        public renderDescription(options: RenderDescriptionOptions = {}) {
            const { size = "md", wrapperClass = "" } = options;
            if (this.description) return this.renderDescriptionText(size, wrapperClass);

            return this.renderDescriptionSlot(size, wrapperClass);
        }

        private onDescriptionChange(size: TextSize) {
            this._hasSlottedDescription = !isEmptySlot(this, "description");

            if (!this._hasSlottedDescription) return;

            const slot = this.shadowRoot.querySelector("slot[name=description]") as HTMLSlotElement;
            const assignedElements = slot.assignedElements({ flatten: true });
            this.setAttributesForAtlasTextElements(assignedElements, { muted: true, size });
        }

        private renderDescriptionText(size: TextSize, wrapperClass?: string) {
            return html` <atlas-text muted class=${wrapperClass} size=${size}> ${this.description} </atlas-text> `;
        }

        private renderDescriptionSlot(size: TextSize, wrapperClass?: string) {
            const textStyle = {
                display: this._hasSlottedDescription ? "" : "none"
            };

            return html`
                <atlas-text muted size=${size} class=${wrapperClass} style="${styleMap(textStyle)}">
                    <slot name="description" @slotchange=${() => this.onDescriptionChange(size)}></slot>
                </atlas-text>
            `;
        }

        private setAttributesForAtlasTextElements(elements: Element[], attributes: Record<string, string | boolean>) {
            elements.forEach((element) => {
                if (element.tagName === "ATLAS-TEXT") this.applyAttributesToElement(element, attributes);

                const childElements = Array.from(element.children);
                if (childElements.length > 0) this.setAttributesForAtlasTextElements(childElements, attributes);
            });
        }

        private applyAttributesToElement(element: Element, attributes: Record<string, string | boolean>) {
            Object.entries(attributes).forEach(([key, value]) => {
                if (typeof value === "boolean") {
                    element.toggleAttribute(key, value);
                } else {
                    element.setAttribute(key, value as string);
                }
            });
        }
    }

    return WithVisibilityIconClass as Constructor<WithDescriptionInterface> & T;
};
