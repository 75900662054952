import { customElement, property, state } from "lit/decorators.js";

import { emit } from "@/internals/events";
import PostalCodeValidator from "@/internals/validators/postal-code-validator";
import Inputmask from "@/vendors/inputmask-utils";
import { removeNonNumeric } from "@/helpers/formatters";
import { get } from "@/helpers/request";

import { type PostalCode, type PostalCodeApiResponse } from "./types";
import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

export type PostalCodeProps = InputProps & {
    "disable-search": string;
};

/**
 * @extends atlas-input
 *
 * @event {CustomEvent} atlas-postal-code-change - Evento lançado quando é feita uma pesquisa pelo CEP informado no campo
 *
 * @tag atlas-postal-code
 */
@customElement("atlas-postal-code")
export default class AtlasPostalCode extends AtlasInput {
    /** Indica se a pesquisa pelo CEP, que é feita através do asaas, deve ser desabilitada */
    @property({ type: Boolean, attribute: "disable-search" }) disableSearch: boolean;

    /** Define a URL da API que pode ser configurada através do atributo `search-url` */
    @property({ type: String, attribute: "search-url" }) searchUrl: string;

    /** Define o nome do parâmetro personalizado para a pesquisa do CEP */
    @property({ type: String, attribute: "custom-param-name" }) customParamName: string = "postalCode";

    @state() private _postalCodeData: PostalCode | object = {};

    private _maskInstance: Inputmask.Instance;

    private mask: object = {
        mask: ["99999-999"],
        jitMasking: true,
        keepStatic: true,
        showMaskOnHover: false
    };

    /** @internal */
    public connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.buildMask();
            this.fillPostalCodeData();
        });
    }

    /**
     * @internal
     * @override
     */
    public onChangeValue() {
        if (!Inputmask.isValid(this.value, this.mask)) {
            this.value = Inputmask.format(this.value, this.mask);
        }

        super.onChangeValue();
    }

    /**
     * Retorna o valor do campo sem a máscara
     * @returns {string} - O valor do campo sem a máscara
     */
    public getUnmaskedValue(): string {
        return this._maskInstance.unmaskedvalue();
    }

    /**
     * Retorna os dados do CEP
     * @returns {PostalCode | {}} - Um objeto com os dados do CEP ou um objeto vazio, caso o endereço não tenha sido encontrado
     */
    public getPostalCodeData(): PostalCode | object {
        return this._postalCodeData;
    }

    /**
     * Faz uma requisição para obter os dados do CEP a partir de uma URL
     * @param {string} postalCode - O CEP a ser pesquisado
     * @returns {Promise<PostalCodeApiResponse>} - Uma Promise contendo os dados da resposta da API
     */
    public fetchPostalCodeData(postalCode: string) {
        const postalCodeValue = removeNonNumeric(postalCode);

        const queryParam = { [this.customParamName]: postalCodeValue };

        return get(this.searchUrl, queryParam) as Promise<PostalCodeApiResponse>;
    }

    /**
     * Preenche os dados do CEP
     */
    public async fillPostalCodeData() {
        if (!this._valid || !this.value || this.disableSearch) {
            return;
        }

        let result: PostalCodeApiResponse = {} as PostalCodeApiResponse;

        this.loading = true;

        try {
            result = await this.fetchPostalCodeData(this.value);
        } catch (e) {
            result = { success: false };
            /* eslint-disable no-console */
            console.error(e);
            /* eslint-enable no-console */
        }

        this._postalCodeData = result.success ? result.postalCode : {};
        this.loading = false;

        emit(this, "atlas-postal-code-change");
    }

    protected async handleInput() {
        await super.handleInput();

        if (Inputmask.isValid(this.value, this.mask)) {
            this.fillPostalCodeData();
        }
    }

    private buildMask() {
        this.placeholder = "00000-000";
        this.inputMode = "numeric";

        this._maskInstance = Inputmask(this.mask).mask(this._input);

        if (this.value) this.value = Inputmask.format(this.value, this.mask);

        this.addValidator(new PostalCodeValidator());
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-postal-code": AtlasPostalCode;
    }
}
