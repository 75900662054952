import { html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";

import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import { WithCollapseMixin, WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";
import { WithDescriptionMixin, type WithDescriptionProps } from "@/internals/mixins/with-description-mixin";

import { getAllFormElements } from "@/helpers/form";
import { emit } from "@/internals/events";
import FormElement from "@/components/form/form-element";

import { HeadingSize } from "@/components/display/atlas-heading/types";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import AtlasElementGroup from "@/components/layout/atlas-element-group/atlas-element-group";

import styles from "./atlas-section.scss";
import "@/components/layout/atlas-collapse/atlas-collapse";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-heading/atlas-heading";

const ComposedClass = WithDescriptionMixin(WithPopoverMixin(WithCollapseMixin(AtlasElement)));

export type SectionProps = AtlasElementProps &
    WithPopoverProps &
    WithCollapseProps &
    WithDescriptionProps & {
        "header": string;
        "optional": boolean;
        "header-size": HeadingSize;
        "is-faq": boolean;
        "hide-optional": boolean;
    };

/**
 *  @slot - Slot padrão onde vai o conteúdo da section
 *  @slot description - Slot onde pode ser incluído a descrição da section - o parâmetro description vai ser priorizado
 *  @slot subheading - Slot onde pode ser incluído um subtítulo da section
 *
 * @tag atlas-section
 */
@customElement("atlas-section")
export default class AtlasSection extends ComposedClass {
    static styles = styles;

    /** Título da seção */
    @property({ type: String }) header: string;

    /** Define o tamanho da tipografia do header */
    @property({ type: String, attribute: "header-size" }) headerSize: HeadingSize = "h4";

    /** Indica se a seção é opcional */
    @property({ type: Boolean, reflect: true }) optional = false;

    /** Define se a seção é de faq */
    @property({ type: Boolean, attribute: "is-faq" }) isFaq = false;

    /** Indica se o texto (Opcional) deve ser oculto do título da seção */
    @property({ type: Boolean, reflect: true, attribute: "hide-optional" }) hideOptional = false;

    /** Indica que a seção não deve ter uma margem inferior */
    @property({ type: Boolean, reflect: true, attribute: "no-margin-bottom" }) noMarginBottom = false;

    @state() private _hasSlottedSubheading = false;

    @state() private _hasSlottedAction = false;

    private _deviceController = new DeviceController(this);

    public connectedCallback() {
        super.connectedCallback?.();

        this.updateOptionalState = this.updateOptionalState.bind(this);
        this.addEventListener("atlas-form-element-required-change", this.updateOptionalState);
        this.addEventListener("atlas-collapse-button-click", this.emitSectionEvent);

        if (this.collapsible) {
            this.collapsed = !this.expanded;
        }

        this.updateComplete.then(() => {
            this.updateOptionalState();
        });
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-form-element-required-change", this.updateOptionalState);
        this.removeEventListener("atlas-collapse-button-click", this.emitSectionEvent);
    }

    /** Abre a seção colapsada */
    public open() {
        if (!this.collapsible) return;

        this.collapsed = false;

        this.emitSectionEvent();
    }

    /** Fecha a seção aberta */
    public close() {
        if (!this.collapsible) return;

        this.collapsed = true;

        this.emitSectionEvent();
    }

    /** Alterna entre aberto e fechado */
    public toggle() {
        if (!this.collapsible) return;

        if (this.collapsed) {
            this.open();
        } else {
            this.close();
        }
    }

    private emitSectionEvent() {
        emit(this, `atlas-section-${this.collapsed ? "collapse" : "expand"}`);
    }

    private async onActionSlotChange() {
        await this.updateComplete;

        const actionsSlot = this.shadowRoot.querySelector("slot[name=action]") as HTMLSlotElement;

        const slottedButtons = actionsSlot?.assignedElements({ flatten: true }) || [];

        slottedButtons.forEach((element) => {
            element.toggleAttribute("block", this._deviceController.isMobile);
        });

        this._hasSlottedAction = slottedButtons.length > 0;
    }

    private async updateOptionalState() {
        await this.updateComplete;

        const formElements = getAllFormElements(this);

        if (formElements.length <= 0) {
            this.optional = false;
            return;
        }

        const allOptional = formElements.every((element: FormElement) => {
            if (element instanceof AtlasElementGroup) {
                return element.requiredFields === 0;
            }

            return !element.required;
        });

        this.optional = allOptional;

        formElements.forEach((element: FormElement) => {
            element.toggleAttribute("hide-optional", allOptional);
        });
    }

    private onSubheadingSlotChange() {
        const subheadingSlot = this.shadowRoot.querySelector("slot[name=subheading]") as HTMLSlotElement;

        this._hasSlottedSubheading = subheadingSlot?.assignedElements().length > 0;
    }

    private shouldRenderOptionalSuffix() {
        return this.optional && !this.hideOptional;
    }

    private renderActionSlot() {
        return html`
            <div class="section-action" style="${!this._hasSlottedAction ? "display: none;" : ""}">
                <slot name="action" @slotchange=${this.onActionSlotChange}></slot>
            </div>
        `;
    }

    private renderSubheadingSlot() {
        return html`
            <div class="section-subheading" style="${!this._hasSlottedSubheading ? "display: none;" : ""}">
                <slot name="subheading" @slotchange=${this.onSubheadingSlotChange}></slot>
            </div>
        `;
    }

    private renderHeading() {
        const headerSize = this.isFaq ? "h6" : this.headerSize;

        return html`
            <atlas-heading
                size="${headerSize}"
                popover-title=${this.popoverTitle}
                popover-content=${this.popoverContent}
            >
                ${this.header}${this.shouldRenderOptionalSuffix() ? " (Opcional)" : ""}
            </atlas-heading>
        `;
    }

    private renderFaqHeader() {
        return html`
            <button class="section-header" @click=${this.toggle}>
                <div class="section-header-items">
                    <div class="section-header-title">
                        ${this.renderHeading()}
                        ${this.renderCollapseButton("primary", "primary")}
                    </div>
                </div>
            </button>
        `;
    }

    private renderMobileHeader() {
        return html`
            <div class="section-header">
                <div class="section-header-items">
                    <button class="section-header-title" @click=${this.toggle}>
                        ${this.renderHeading()}
                        ${this.renderCollapseButton("primary", "secondary")}
                    </button>
                    ${this.renderSubheadingSlot()}
                    ${this.renderActionSlot()}
                </div>
            </div>
        `;
    }

    private renderCollapsibleHeader() {
        if (this.isFaq) {
            return this.renderFaqHeader();
        }

        if (this._deviceController.isMobile) {
            return this.renderMobileHeader();
        }

        return html`
            <div class="section-header">
                <div class="section-header-items">
                    <div class="section-header-title">
                        ${this.renderHeading()}
                        ${this.renderCollapseButton("primary", "secondary")}
                    </div>
                    ${this.renderActionSlot()}
                </div>

                ${this.renderSubheadingSlot()}
            </div>
        `;
    }

    private renderDefaultHeader() {
        return html`
            <div class="section-header">
                <div class="section-header-items">
                    <div class="section-header-title">${this.renderHeading()}</div>
                    ${this.renderActionSlot()}
                </div>

                ${this.renderSubheadingSlot()}
            </div>
        `;
    }

    private renderHeader() {
        if (!this.header) return nothing;

        return when(
            this.collapsible,
            () => this.renderCollapsibleHeader(),
            () => this.renderDefaultHeader()
        );
    }

    private renderContent() {
        const contentHtml = html`
            ${this.renderDescription({ wrapperClass: "section-description" })}
            <div class="section-content">
                <slot></slot>
            </div>
        `;

        return when(
            this.collapsible,
            () => this.renderContentWithCollapse(contentHtml),
            () => contentHtml
        );
    }

    public render() {
        const sectionClass = {
            "section": true,
            "collapsible": this.collapsible,
            "collapsed": this.collapsed,
            "is-faq": this.isFaq
        };

        return html`
            <div class=${classMap(sectionClass)}>
                ${this.renderHeader()}
                <div class="section-body">${this.renderContent()}</div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-section": AtlasSection;
    }
}
